.admin-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // flex-flow: wrap;
  flex-flow: row-reverse;

  gap: 1rem;

  .header-action {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    gap: 1rem;

    .header-buttons {
      display: flex;
      gap: 0.5rem;
      width: 100%;
      justify-content: space-between;
    }
  }
}
 
 

.admin-profile-container {
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f8f9fa;
  max-width: 560px;
  width: 100%;
  padding: 25px;
  margin: auto;

  .admin-profile-body {
    .heading {
      font-size: 1.25em;
      text-align: center;
    }

    .profile-picture-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      position: relative;

      .profile-picture-container {
        // border-radius: 50%;
        //style change
        border-radius: 0;
        overflow: hidden;
        height: 100px;
        width: 100px;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;

        .profile-picture {
          height: 100%;
          object-fit: cover;
          width: 100%;
          object-position: center;
        }

        .upload-file-block {
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: rgba(90, 92, 105, 0.7);
          color: #f8f9fc;
          font-size: 12px;
          font-weight: 600;
          opacity: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.2s;
        }
      }

      .profile-picture-container {
        .upload-file-block {
          opacity: 1;
        }
      }
    }

    .input-group {
      .form-floating {
        width: 100%;
      }
    }

    .edit-profile-action-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.reg-card{

 min-height: '80dvh';
}

.reg-card-body{
 margin-top: 10px;
 margin-bottom: 10px;
}

.alertText{
  font-size: medium;
  margin: 15px;
}

.add-action-buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .btn{
    width: 100%;
  }
}

.drop-code-signin-admin {
  height: 40px !important;
  position: absolute !important;
  left: 4px !important;
  width: 100px !important;
}

.pagination-buttons {
  .page-item {
    cursor: pointer;
  }

  .disabled {
    cursor: no-drop;
  }
}

.table-actions {
  display: flex;
  align-items: center;
  // justify-content: center;
  gap: 1rem;

  .disabled {
    cursor: not-allowed;
  }

  svg {
    cursor: pointer;
  }
}

.category-activation-switch {
  width: fit-content;
  margin: auto;

  input {
    cursor: pointer;
  }
}
.adminBtn {
  float: right;
}

.edit-admin-page,
.add-admin-page {
  width: 100%;
  max-width: 460px;
  padding: 25px;
  margin: auto;

  .heading {
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 2rem;
  }

  .input-group {
    .form-floating {
      width: 100%;
    }
  }

  .edit-user-action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
  }
}

.edit-admin-details-page {
  width: 100%;
  max-width: 1000px;
  padding: 35px;
  margin: auto;

  .heading {
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 2rem;
  }

  .input-group {
    .form-floating {
      width: 100%;
    }
  }
}

.errText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.li_item {
  height: 145px;
  overflow-y: auto;
}

.contact-div {
  .form-floating > label {
    margin-left: 90px;
  }
}

.drop-code {
  height: 40px;
  position: absolute;
  top: 0px;
  left: 3px;
  width: 100px;
}
.form-control {
  display: block;
  // width: 114%;
}

.mar-10 {
  display: flex;
  float: right;
  margin-top: -15px;
  width: 70% !important;
  margin-right: 1px;
}

.edit-drop-code {
  height: 40px;
  position: absolute;
  top: 15px;
  left: 0px;
  width: 100px;
}
.pointerEventsDisable {
  pointer-events: none;
}

.profile-picture-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;

  .profile-picture-container {
    // border-radius: 50%;
    //style change
    border-radius: 0;
    overflow: hidden;
    height: 100px;
    width: 100px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    .profile-picture {
      height: 100%;
      object-fit: cover;
      width: 100%;
      object-position: center;
    }

    .upload-file-block {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(90, 92, 105, 0.7);
      color: #f8f9fc;
      font-size: 12px;
      font-weight: 600;
      opacity: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s;
    }
  }

  .profile-picture-container:hover {
    .upload-file-block {
      opacity: 1;
    }
  }
}

.f-25 {
  font-size: 13px;
}

.back-admin-action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.salon-info-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content:space-around;
  gap:10px;
  padding: 10px;
}

.salon-info {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.salon-info-left, .salon-info-right {
  flex: 1;
  padding: 0 10px;
}

.salon-info-header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #6c757d;
}

.salon-name {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.salon-location {
  font-size: 16px;
  color: #666;
}

.text-right {
  text-align: right;
}