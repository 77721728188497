$primary-dark: #3d0031 !default;
$primary-base: #844c7a !default;
$primary-50: #d8a1b6 !default;
$primary-25: #fdf6f7 !default;

.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-header {
  background: $primary-25 !important;
  justify-content: center;
  display: flex;
}

.header-container {
  padding: 1px;
}
.main-content {
  flex-grow: 1;
}

.page-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.page-content {
  display: 'flex';
  flex-direction: 'column';
  padding: auto;
  min-width: 80%;
}

.main-footer {
  background: $primary-25 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.store-container {
  display: flex;
  justify-content: center;
}

.store-img {
  width: 150px;
  height: 150px;
}

.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  margin: 1rem;
}

.inputStyle {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 25px !important;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  outline: 0 !important;
}

.timer {
  display: flex;
  justify-content: center;
  height: 31px;
  font-size: 25px;
}

// .ml5 {
//   margin-right: 200px;
// }

.mainOtp {
  width: 99vw;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.otp-input-screen {
  width: 3rem;
  height: 3rem;
  font-size: 20px !important;
  border-radius: 4px;
  padding: 5x;
  outline: 0;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
  user-select: none;
}

.otp-input-screen:focus {
  outline: none;
}
