.edit-user-page,
.add-user-page {
  width: 100%;
  max-width: 460px;
  padding: 25px;
  margin: auto;

  .heading {
    font-size: 1.5em;
    text-align: center;
    margin-bottom: 2rem;
  }

  .input-group {
    .form-floating {
      width: 100%;
    }
  }

  .edit-user-action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.userBtn {
  float: right;
}

.errText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerTop {
 display: flex;
 justify-content: center;
 align-items: center;
 
}

$primary-dark: #3d0031 !default;
$primary-base: #844c7a !default;
$primary-50: #d8a1b6 !default;
$primary-25: #fdf6f7 !default;


.color-primary{
  color: $primary-dark;
}

.booking-footer{
  background: $primary-25 !important;
 height: 20dvh;
display: flex;
justify-content: center;
align-items: center;

}


.booking-header{
  background: $primary-25 !important;
}

.booking-logo{
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
ul.custom-bullets li:before {
  content: "\2022"; /* Unicode character for bullet point */
  color: #ff0000; /* Change color as needed */
  font-size: 16px; /* Adjust size as needed */
  margin-right: 5px; /* Adjust spacing between bullet and text */
}