$primary-dark: #3d0031 !default;
$primary-base: #844c7a !default;
$primary-50: #d8a1b6 !default;
$primary-25: #fdf6f7 !default;

.HyperPayWidget {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fdf6f7;
  flex-grow: 0.5;

  #order-payment {
    min-width: inherit;
    min-height: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .order-pay {
    min-width: inherit;
    min-height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .apple-pay-button {
    display: inline-block;
    -webkit-appearance: -apple-pay-button !important;
    -apple-pay-button-type: book; /* Use any supported button type. */
  }

  .wpwl-button-pay {
    background-color: $primary-dark !important;
    border: 1px solid #844c7a;
    border-radius: 8px;
  }

  .wpwl-control-brand {
    opacity: 0.6;
    color: #555;
  }

  .wpwl-control {
    border-radius: 8px;
  }
  .wpwl-control:focus {
    border-color: #3d0031;
  }
  .wpwl-control:active {
    border-color: #3d0031;
  }

  .wpwl-label {
    font-weight: bold;
  }

  .wpwl-container-card {
    // background-color: #fff;
    padding: 16px;
  }
}

.wpwl-form-card{
  border: 2px solid #844c7a;
  border-radius: 10px;


}
.main-container {
  background-color: #fdf6f7;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-header {
  background: $primary-25 !important;
  justify-content: center;
  display: flex;
}

.header-container {
  padding: 1px;
}
.main-content {
  flex-grow: 1;
}

.page-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.page-content {
  display: 'flex';
  flex-direction: 'column';
  padding: auto;
  min-width: 80%;
}

.main-footer {
  // background: $primary-25 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.store-container {
  display: flex;
  justify-content: center;
}

.store-img {
  width: 150px;
  height: 150px;
}
